import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import type { ApiKey } from '@socrata/core-api-keys-api';

import I18n from 'common/i18n';
import ResultsTable from 'common/components/ResultsTable';
import ScreenReaderOnly from 'common/a11y/screen-reader-only';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

import type { SearchParams } from './types';
import {
  CREATED_AT_COLUMN_NAME,
  DELETED_AT_COLUMN_NAME,
  NAME_COLUMN_NAME,
  LAST_USED_AT_COLUMN_NAME
} from './ApiKeysConstants';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './Ap... Remove this comment to see the full error message
import ApiKeysActionsDropdownButton from './ApiKeysActionsDropdownButton';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './Ap... Remove this comment to see the full error message
import ApiKeysTableSearch from './ApiKeysTableSearch';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './Ap... Remove this comment to see the full error message
import ApiKeysTablePagination from './ApiKeysTablePagination';
import { fetchCurrentPage, changeSortColumn } from './Redux/actions.js';
import { getCurrentPage, getCurrentSearchParams } from './Redux/selectors.js';
import { getResultsCountMessage } from '../helpers';
import ApiKeysErrorMessage from './ApiKeysErrorMessage';

interface OwnProps {}

interface StateProps {
  currentSearchParams: SearchParams;
  currentPage: ApiKey[];
}

interface DispatchProps {
  doFetchCurrentPage: () => void;
  doChangeSortColumn: (
    sortedColumn:
      | typeof NAME_COLUMN_NAME
      | typeof LAST_USED_AT_COLUMN_NAME
      | typeof CREATED_AT_COLUMN_NAME
      | typeof DELETED_AT_COLUMN_NAME
  ) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

/** Table that handles showing, sorting, searching, creating, and deleting API keys */
class ApiKeysTable extends Component<Props> {
  componentDidMount() {
    this.onMount();
  }

  onMount = () => {
    // fetch a page with the default search params
    this.props.doFetchCurrentPage();
  };

  renderHeader = () => (
    <div>
      <h2>{I18n.t('screens.profile.edit.api_keys.header')}</h2>
      <details className="developer-settings__details">
        <summary>
          {I18n.t('screens.profile.edit.api_keys.intro_summary')} <SocrataIcon name={IconName.Question} />
        </summary>
        <p>{I18n.t('screens.profile.edit.api_keys.intro')}</p>
        <p>{I18n.t('screens.profile.edit.api_keys.intro_secret')}</p>
        <p
          dangerouslySetInnerHTML={{ __html: I18n.t('screens.profile.edit.api_keys.intro_dev_link_html') }}
        />
      </details>
    </div>
  );

  render() {
    const { currentPage, currentSearchParams, doChangeSortColumn } = this.props;
    const { sortBy, sortOrder } = currentSearchParams;
    const resultsCountMessage = getResultsCountMessage(currentPage);

    return (
      <div>
        {this.renderHeader()}

        <ApiKeysErrorMessage />
        {/** Search and create new API Key  */}
        <ApiKeysTableSearch />
        <ScreenReaderOnly content={resultsCountMessage} />

        <ResultsTable
          loadingData={currentPage === null}
          data={currentPage || []}
          rowKey="keyId"
          noResultsMessage={I18n.t('controls.common.no_results')}
          id="api-keys-table"
          tabIndex={0}
          aria-label={resultsCountMessage}
        >
          {/* Api Key Name */}
          <ResultsTable.Column
            header={I18n.t('screens.profile.edit.api_keys.table.headers.name')}
            dataIndex="keyName"
            isActive={sortBy === NAME_COLUMN_NAME}
            onSort={() => doChangeSortColumn(NAME_COLUMN_NAME)}
            sortDirection={sortOrder}
          />

          {/* Api Key ID */}
          <ResultsTable.Column
            header={I18n.t('screens.profile.edit.api_keys.table.headers.id')}
            dataIndex="keyId"
          />

          {/* Last Used At */}
          <ResultsTable.Column
            header={I18n.t('screens.profile.edit.api_keys.table.headers.last_used_at')}
            dataIndex="lastUsedAtTimestamp"
            isActive={sortBy === LAST_USED_AT_COLUMN_NAME}
            onSort={() => doChangeSortColumn(LAST_USED_AT_COLUMN_NAME)}
            sortDirection={sortOrder}
          >
            {(lastUsedAtTimestamp: Date) => (
              <span>{lastUsedAtTimestamp && moment(lastUsedAtTimestamp).format('LLLL')}</span>
            )}
          </ResultsTable.Column>

          {/* Created At */}
          <ResultsTable.Column
            header={I18n.t('screens.profile.edit.api_keys.table.headers.created_at')}
            dataIndex="createdAtTimestamp"
            isActive={sortBy === CREATED_AT_COLUMN_NAME}
            onSort={() => doChangeSortColumn(CREATED_AT_COLUMN_NAME)}
            sortDirection={sortOrder}
          >
            {(createdAtTimestamp: Date) => <span>{moment(createdAtTimestamp).format('LLLL')}</span>}
          </ResultsTable.Column>

          {/* Deleted At (only shown if includeDeleted is true) */}
          {currentSearchParams.includeDeleted && (
            <ResultsTable.Column
              header={I18n.t('screens.profile.edit.api_keys.table.headers.deleted_at')}
              dataIndex="deletedAt"
              isActive={sortBy === DELETED_AT_COLUMN_NAME}
              onSort={() => doChangeSortColumn(DELETED_AT_COLUMN_NAME)}
              sortDirection={sortOrder}
            >
              {(deletedAt: Date) => <span>{deletedAt && moment(deletedAt).format('LLLL')}</span>}
            </ResultsTable.Column>
          )}

          {/* Actions */}
          <ResultsTable.Column
            header={I18n.t('screens.profile.edit.api_keys.table.headers.actions')}
            dataIndex="keyId"
          >
            {(keyId: string, apiKey: string) => (
              <ApiKeysActionsDropdownButton keyId={keyId} apiKey={apiKey} />
            )}
          </ResultsTable.Column>
        </ResultsTable>

        <ApiKeysTablePagination />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentPage: getCurrentPage(state),
  currentSearchParams: getCurrentSearchParams(state)
});

const mapDispatchToProps = {
  doFetchCurrentPage: fetchCurrentPage,
  doChangeSortColumn: changeSortColumn
};

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(ApiKeysTable);
