import { select, call, put, takeEvery } from 'redux-saga/effects';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import ApiKeysApi from 'common/core/api_keys';
import { GetKeysRequest } from '@socrata/core-api-keys-api';
import { PAGE_SIZE } from '../ApiKeysConstants';
import * as actions from './actions.js';
import { getCurrentSearchParams } from './selectors.js';

export function* fetchCurrentPage() {
  try {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const searchParams = yield select(getCurrentSearchParams) as GetKeysRequest;
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const nextPage = yield call([ApiKeysApi, ApiKeysApi.getKeys], omitBy(searchParams, isNil));

    const hasNextPage = nextPage.length > PAGE_SIZE;
    if (hasNextPage) {
      nextPage.pop();
    }

    yield put(actions.fetchCurrentPageSuccess(nextPage, hasNextPage));
  } catch (error) {
    yield put(actions.fetchCurrentPageFail(error));
  }
}

export function* onDeleteApiKey({ keyId }: { keyId: string }) {
  try {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const deletedKey = yield call([ApiKeysApi, ApiKeysApi.deleteKey], { keyId });
    yield put(actions.deleteKeySuccess(deletedKey));
  } catch (error) {
    yield put(actions.deleteKeyFail(error));
  }
}

export function* onCreateKey({ keyName }: { keyName: string }) {
  try {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const createdKey = yield call([ApiKeysApi, ApiKeysApi.createKey], { apiKey: { keyName } });
    yield put(actions.createKeySuccess(createdKey));
    yield put(actions.applySearch());
  } catch (error) {
    // error.json() here does not return a promise so we have to promisify it
    const parseError = () =>
      new Promise((resolve, reject) => {
        error
          .json()
          .then((parsed: any) => resolve(parsed))
          .catch((e: any) => reject(`Error parsing error JSON: ${e}`));
      });

    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const errorJson = yield call(parseError);
    yield put(actions.createKeyFail(errorJson));
  }
}

export default [
  takeEvery(actions.API_KEYS_FETCH_CURRENT_PAGE, fetchCurrentPage),
  takeEvery(actions.API_KEYS_GO_TO_NEXT_PAGE, fetchCurrentPage),
  takeEvery(actions.API_KEYS_GO_TO_PREVIOUS_PAGE, fetchCurrentPage),
  takeEvery(actions.API_KEYS_CHANGE_SORT_COLUMN, fetchCurrentPage),
  takeEvery(actions.API_KEYS_APPLY_SEARCH, fetchCurrentPage),
  takeEvery(actions.API_KEYS_DELETE_KEY, onDeleteApiKey),
  takeEvery(actions.API_KEYS_CREATE_KEY, onCreateKey)
];
